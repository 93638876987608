import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TealiumUtagService } from "./tealium/tealium-utag-service.service";
import { FormControl, Validators } from "@angular/forms";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from "@angular/router";
import { environment } from "../environments/environment";
import { filter } from "rxjs/operators";
import { LoaderService } from "./shared/services/loader.service";
import { Observable, Subscription } from "rxjs";
import { UserDataService } from "./core/services/user-data/user-data.service";
import {
  EventBusData,
  Products,
  UserInformation,
} from "./core/models/Customer";
import { EventBusService } from "./shared/services/eventBus/event-bus.service";
import { ChannelsTopicEnum } from "./shared/utils/enums/channels-topics.enum";
import { TransactionIdService } from "./core/services/transaction/transaction-id.service";
import { TechnicalErrorComponent } from "./shared/components/technical-error/technical-error.component";
import { AsyncPipe, CommonModule } from "@angular/common";
import { ConfigService } from "./core/services/config/config.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { Routes } from "./shared/utils/enums/routes.enum";
@Component({
  providers: [TealiumUtagService],
  selector: "app-cda-integration",
  standalone: true,
  imports: [RouterOutlet, TechnicalErrorComponent, AsyncPipe, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "bavv-pasivo-frontend-cda-integration-mf";
  public searchControl: FormControl = new FormControl(null, [
    Validators.required,
  ]);
  currentUrl!: string;
  previousUrl!: string;
  isload!: boolean;
  public subscriptions = new Subscription();
  hasTechnicalError = toSignal(
    this.verifyTechnicalError$().pipe((data) => data),
    { initialValue: false },
  );
  constructor(
    private tealiumUtagService: TealiumUtagService,
    private router: Router,
    private readonly userDataService: UserDataService,
    private readonly serviceBus: EventBusService,
    private readonly loaderService: LoaderService,
    private readonly transactionIdService: TransactionIdService,
    private readonly configService: ConfigService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.tealiumConfig();
  }
  ngAfterViewInit(): void {
    this.loadingStatus();
  }

  ngOnInit(): void {
    this.startChannelsListener();
    this.listenerAccountsEvent();
    this.suscribeToRouteEvents();
    this.validateHistoryState();
    this.validateNavigationChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  detectNavigationBackButtonBrowser() {
    let subscribeRoute = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === "popstate") {
          this.router.navigateByUrl(Routes.homeChannels);
        }
      }
    });
    this.subscriptions.add(subscribeRoute);
  }

  validateNavigationChanges(): void {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter(
          (event: any): event is NavigationStart =>
            event instanceof NavigationStart,
        ),
      )
      .subscribe((event) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  startChannelsListener(): void {
    let subCustomerInformation = this.serviceBus
      .startListener<EventBusData<UserInformation>>(
        ChannelsTopicEnum.basicCustomerInformation,
      )
      .subscribe((data) => {
        this.saveCustomerData(data.value);
        this.generateTransactionId(data.value);
      });
    this.subscriptions.add(subCustomerInformation);
  }

  listenerAccountsEvent(): void {
    let subAccountsInfo = this.serviceBus
      .startListener<EventBusData<Products[]>>(
        ChannelsTopicEnum.basicCustomerAccounts,
      )
      .subscribe((data) => {
        this.saveAccountsInfo(data.value);
      });
    this.subscriptions.add(subAccountsInfo);
  }

  saveCustomerData(customerData: UserInformation): void {
    this.userDataService.customerData.userInformation = customerData;
  }

  saveAccountsInfo(accounts: Products[]) {
    this.userDataService.customerData.accountInformation = [
      {
        products: [],
        typeProduct: 0,
        name: "",
        description: "",
        balanceTotal: 0,
        quantity: 0,
      },
    ];
    this.userDataService.customerData.accountInformation[0].products = accounts;
  }

  tealiumConfig(): void {
    this.tealiumUtagService.setConfig({
      account: environment.account,
      profile: environment.profile,
      environment: environment.ambiente,
      sync: false,
    });

    this.tealiumUtagService.setConfig({
      account: environment.account,
      profile: environment.profile,
      environment: environment.ambiente,
      sync: true,
    });

    this.tealiumUtagService.createTagSync();
  }

  loadingStatus() {
    let $status = this.loaderService.getStatusLoader().subscribe((data) => {
      this.isload = data;
      this.cdr.detectChanges();
    });
    this.subscriptions.add($status);
  }

  suscribeToRouteEvents() {
    this.router.events
      .pipe(
        filter(
          (event: any): event is NavigationEnd =>
            event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.observableAnalitics(event);
      });
  }

  observableAnalitics(event: NavigationEnd) {
    this.tealiumUtagService.track("view", {
      event: "view",
      pagePath: `/bancadigital/abrir-cuenta-ahorro${event.url}`,
    });
  }

  verifyTechnicalError$() {
    return this.configService.getHasTechnicalError();
  }

  generateTransactionId(customerData: UserInformation) {
    this.transactionIdService.generateTransactionId(
      customerData.documentNumber,
    );
  }
  validateHistoryState(): void {
    window.onpopstate = (e) => {
      if ("singleSpaTrigger" in e) {
        return;
      } else {
        history.replaceState(null, "", "/bancadigital");
      }
    };
  }
}
