import { environment } from "../../../../environments/environment";

export const ENDPOINTS = {
  texts: "/parameters/cda-integration/template-texts.json",
  constants: "/parameters/cda-integration/constants.json",
  insuranceValidation: "/insurance-validation",
  getProductConfig: "/get-product-configuration",
  generateOTP: "/bavv-identification/otp-generation",
  validateOTP: "/bavv-identification/otp-validation",
  cipherKey: "/get-cipher-key",
  readCustomerData: "/read-customer-data",
  createProduct: "/product/create-product",
  readSummaryTransaction: "/read-summary-transaction",
  coverageCities: "/parameters/cda-integration/cities-coverage-domina.json",
  companyAgreement: "/get-company-agreement",
  createDebitCard: "/create-debit-card",
};

export const ExcludedPaths: string[] = [
  environment.urlAssets + "/parameters/cda-integration",
  environment.urlBase + ENDPOINTS.cipherKey,
  environment.urlBase + ENDPOINTS.getProductConfig,
  environment.urlBase + ENDPOINTS.createProduct,
  environment.urlBase + ENDPOINTS.readSummaryTransaction,
  environment.urlBase + ENDPOINTS.companyAgreement,
  environment.urlBase + ENDPOINTS.createDebitCard,
];
