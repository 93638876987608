import { Injectable } from "@angular/core";
import { BasicData } from "../../models/Customer";

@Injectable({
  providedIn: "root",
})
export class UserDataService {
  private basicDataCustomer: BasicData;

  constructor() {
    this.basicDataCustomer = {
      accountInformation: [],
    } as unknown as BasicData;
  }

  get customerData(): BasicData {
    return this.basicDataCustomer;
  }

  set customerData(data: BasicData) {
    this.basicDataCustomer = data;
  }
}
